<template>
  <v-autocomplete
    dense
    outlined
    prepend-inner-icon="mdi-filter-menu-outline"
    v-model="selected"
    :items="items"
    :label="label"
    multiple
    deletable-chips
    clearable
    :item-text="itemText"
    :item-value="itemValue"
  >
    <template #selection="{ item, index }">
      <v-chip small v-if="index < limit">
        <span class="text-truncate">{{ formatText(item) }}</span>
      </v-chip>
      <HelperMessage>
        <span v-if="index === limit" class="grey--text text-caption">
          (+{{ value.length - limit }} más)
        </span>
        <template #message>
          <span class="text-caption">{{ message }}</span>
        </template>
      </HelperMessage>
    </template>
  </v-autocomplete>
</template>

<script>
import HelperMessage from "../../common/HelperMessage.vue";
export default {
  name: "FilterAutocomplete",
  components: {
    HelperMessage
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: "Filtrar por ..."
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    }
  },
  data() {
    return {
      // array para almacenar internamente los elementos seleccionados
      selected: []
    };
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(newValue) {
      this.$emit("input", newValue);
    }
  },
  computed: {
    message() {
      if (!this.itemText) return this.selected.join(",");

      const mapItems = this.items.reduce((acc, item) => {
        acc[item[this.itemValue]] = item[this.itemText];
        return acc;
      }, {});

      return this.selected.map(item => mapItems[item]).join(",");
    }
  },
  methods: {
    formatText(item) {
      if (!this.itemText) return item;
      return item[this.itemText];
    }
  }
};
</script>
